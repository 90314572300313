import { useState, useContext } from "react"; //import useState
import { GlobalContext } from "../context/GlobalState";
import React from "react";

const AddTransaction = () => {
  const [text, setText] = useState(""); // to manipulate text label entered
  const [amount, setAmount] = useState(0); //to manipulate amount label entered

  //add button
  const { addTransaction } = useContext(GlobalContext);

  //submit data
  const onSubmit = (e) => {
    e.preventDefault();

    //build new transaction list
    const newTransaction = {
      id: Math.floor(Math.random() * 100000),
      text,
      amount: parseFloat(amount), //convert neg value of string float(no double in JS)
    };

    //add transaction
    addTransaction(newTransaction);
  };

  return (
    <div>
      <h3>Add New Expense</h3>
      <form onSubmit={onSubmit}>
        <div className='form-control'>
          <label htmlFor='text'>Expense Name</label>
          <input
            type='text'
            value={text} //add in {}
            onChange={(e) => setText(e.target.value)} //event function to manipulate text entered in text value
            placeholder='Enter text...'
          />
        </div>
        <div className='form-control'>
          <label htmlFor='amount'>
            Expense Amount <br />
            [use +ive: Income (500)] <br />
            [use -ive: Expense (-23.99)]
          </label>
          <input
            type='number'
            value={amount} //add in {}
            onChange={(e) => setAmount(e.target.value)} //event function to manipulate text entered in text value
            placeholder='Enter amount...'
          />
        </div>
        <button className='btn'>Add Expense</button>
      </form>
    </div>
  );
};

export default AddTransaction;
