import React from "react";
import { useContext } from "react";
import { GlobalContext } from "../context/GlobalState";

const Balance = () => {
  //use context to manipulate balance
  const { transaction } = useContext(GlobalContext);

  //loop through all transactions and return amounts
  const amounts = transaction.map((transaction) => transaction.amount);

  //sum all amounts, reduce adds totals , toFixed returns decimals
  const total = amounts.reduce((acc, item) => (acc += item), 0).toFixed(2);
  return (
    <div>
      <h4>Your Balance</h4>
      <h1>$ {total}</h1>
    </div>
  );
};

export default Balance;
