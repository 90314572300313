import React from "react";
import { useContext } from "react";
import { GlobalContext } from "../context/GlobalState";

const IncomeExpense = () => {
  const { transaction } = useContext(GlobalContext);

  //loop through amounts
  const amounts = transaction.map((transaction) => transaction.amount);

  const income = amounts
    .filter((item) => item > 0)
    .reduce((acc, item) => (acc += item), 0)
    .toFixed(2);

  //expenses but in positive value *(-1)
  const expenses =
    amounts
      .filter((item) => item < 0)
      .reduce((acc, item) => acc + item, 0)
      .toFixed(2) * -1;
  return (
    <div className='inc-exp-container'>
      <div>
        <h4>Income</h4>
        <p className='money plus'>+${income}</p>
      </div>
      <div>
        <h4>Expense</h4>
        <p className='money minus'>-${expenses}</p>
      </div>
    </div>
  );
};

export default IncomeExpense;
