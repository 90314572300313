import React from "react";
import { useContext } from "react";
import { GlobalContext } from "../context/GlobalState";

const TransactionList = () => {
  const { transaction } = useContext(GlobalContext);

  //delete button
  const { deleteTransaction } = useContext(GlobalContext);

  //assign a sign to money value = transaction.amount < 0 ? "-" : "+";

  return (
    <div>
      <h3>History</h3>
      <ul className='list'>
        {transaction.map((transaction) => (
          <li className={transaction.amount < 0 ? "minus" : "plus"}>
            {transaction.text}
            <span>
              {transaction.amount < 0 ? "-" : "+"}$
              {Math.abs(transaction.amount)}
            </span>
            <button
              onClick={() => deleteTransaction(transaction.id)}
              className='delete-btn'
            >
              x
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TransactionList;
